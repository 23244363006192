import Label from "src/components/Label";
import SvgIconStyle from "src/components/SvgIconStyle";
import { PATH_DASHBOARD } from "src/routes/paths";
import useLocales from "src/hooks/useLocales";

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  settings: getIcon("ic_settings"),
  tools: getIcon("ic_tools"),
  member_management: getIcon("ic_member_management"),
  subadmin: getIcon("ic_subadmin"),
  holdingtank: getIcon("ic_holdingtank"),
  store: getIcon("ic_store"),
  user_subscription: getIcon("ic_user_subscription"),
  report: getIcon("ic_report"),
  restaurant: getIcon("ic_res"),
};

const useNavConfig = () => {
  const { translate } = useLocales();

  return [
    {
      items: [
        {
          title: translate("dashboard"),
          path: PATH_DASHBOARD.dashboard.root,
          icon: ICONS.dashboard,
          children: [
            {
              title: translate("business"),
              path: PATH_DASHBOARD.dashboard.business,
            },
            {
              title: translate("network"),
              path: PATH_DASHBOARD.dashboard.network,
            },
          ],
        },

        {
          title: translate("genealogy"),
          path: PATH_DASHBOARD.genealogy.root,
          icon: ICONS.kanban,

          children: [
            {
              title: translate("sponsor"),
              path: PATH_DASHBOARD.genealogy.sponsor,
            },
            {
              title: translate("binary"),
              path: PATH_DASHBOARD.genealogy.binary,
            },

            {
              title: "Silver Genealogy",
              path: PATH_DASHBOARD.genealogy.silver,
            },

            {
              title: "Gold Genealogy",
              path: PATH_DASHBOARD.genealogy.gold,
            },

            // { title: translate("tree"), path: PATH_DASHBOARD.genealogy.tree },
          ],
        },
        {
          title: translate("financial"),
          path: PATH_DASHBOARD.financial.root,
          icon: ICONS.ecommerce,
          children: [
            {
              title: translate("myEWallet"),
              path: PATH_DASHBOARD.financial.ewallet,
            },
            // {
            //   title: translate("depositWallet"),
            //   path: PATH_DASHBOARD.financial.deposit_wallet,
            // },
            {
              title: translate("fundCredits"),
              path: PATH_DASHBOARD.financial.fund_credits,
            },
            {
              title: translate("payout"),
              path: PATH_DASHBOARD.financial.payout,
            },
            {
              title: "Pending Payments",
              path: PATH_DASHBOARD.financial.pending_payments,
            },
            // {
            //   title: translate("sales"),
            //   path: PATH_DASHBOARD.financial.sales,
            // },
            // {
            //   title: "Sales",
            //   path: "app/business/latest-sales",
            // },
            // {
            //   title: "Financial Report",
            //   path: PATH_DASHBOARD.financial.financial_report,
            // },
          ],
        },
        {
          title: translate("communication"),
          path: PATH_DASHBOARD.communication.root,
          icon: ICONS.chat,
          children: [
            {
              title: translate("blogs"),
              path: PATH_DASHBOARD.communication.blog,
            },
            {
              title: translate("faqs"),
              path: PATH_DASHBOARD.communication.com_faqs,
            },
            // {
            //   title: translate("emails"),
            //   path: PATH_DASHBOARD.communication.mails,
            // },
            {
              title: translate("helpCenter"),
              path: PATH_DASHBOARD.communication.help_center,
            },

            // {
            //   title: "Support Tickets",
            //   children: [
            //     {
            //       title: "Department",
            //       path: PATH_DASHBOARD.communication.com_support_department,
            //     },
            //     {
            //       title: "Categories",
            //       path: PATH_DASHBOARD.communication.com_support_categories,
            //     },
            //     {
            //       title: "Priorites",
            //       path: PATH_DASHBOARD.communication.com_support_priorities,
            //     },
            //     {
            //       title: "Canned Responses",
            //       path: PATH_DASHBOARD.communication.com_support_canned_response,
            //     },
            //     {
            //       title: "Tickets",
            //       path: PATH_DASHBOARD.communication.com_support_tickets,
            //     },
            //   ],
            // },
            // {
            //   title: translate("article"),
            //   path: PATH_DASHBOARD.communication.com_article,
            // },
          ],
        },
        {
          title: translate("restaurant"),
          path: PATH_DASHBOARD.restaurant.root,
          icon: ICONS.restaurant,
          children: [
            // { title: 'Faqs', path: PATH_DASHBOARD.tools.faqs },
            {
              title: translate("Sponsors"),
              path: PATH_DASHBOARD.restaurant.restaurantSponsors,
            },
            {
              title: translate("Booster"),
              path: PATH_DASHBOARD.restaurant.booster,
            },
            // {
            //   title: translate("orders"),
            //   path: PATH_DASHBOARD.restaurant.restaurantOrders,
            // },
          ],
        },

        {
          title: translate("tools"),
          path: PATH_DASHBOARD.tools.root,
          icon: ICONS.tools,
          children: [
            // { title: 'Faqs', path: PATH_DASHBOARD.tools.faqs },
            {
              title: translate("dcouments"),
              path: PATH_DASHBOARD.tools.documents,
            },

            { title: translate("videos"), path: PATH_DASHBOARD.tools.videos },
            { title: translate("Notices"), path: PATH_DASHBOARD.tools.notice },
          ],
        },
        {
          title: translate("memberManagement"),
          path: PATH_DASHBOARD.members.root,
          icon: ICONS.member_management,
          children: [
            {
              title: translate("networkMembers"),
              path: PATH_DASHBOARD.members.network,
            },
            {
              title: "Bank Accounts",
              path: PATH_DASHBOARD.members.bankAccount,
            },
            {
              title: "Booster Rank Achievers",
              path: PATH_DASHBOARD.members.booster_rank,
            },
            {
              title: "Genealogy Users",
              path: PATH_DASHBOARD.members.genealogy_users,
            },

            // {
            //   title: "Holding Tank",
            //   path: PATH_DASHBOARD.members.holdingTank,
            // },
          ],
        },
        // {
        //   title: 'Assign Subscriptions',
        //   path: PATH_DASHBOARD.subscriptions.root,
        //   icon: ICONS.blog,
        //   children: [
        //     { title: 'Add Product', path: PATH_DASHBOARD.subscriptions.add_product },
        //     { title: 'Add business Builder Fee', path: PATH_DASHBOARD.subscriptions.add_business },
        //   ],
        // },
        // {
        //   title: translate("subAdmin"),
        //   path: PATH_DASHBOARD.subAdmin.root,
        //   icon: ICONS.subadmin,
        //   children: [
        //     {
        //       title: translate("subAdmins"),
        //       path: PATH_DASHBOARD.subAdmin.sub_admins,
        //     },
        //   ],
        // },
        // {
        //   title: "Holding-Tank",
        //   path: PATH_DASHBOARD.holdingTank.root,
        //   icon: ICONS.holdingtank,
        //   info: (
        //     <Label variant="outlined" color="warning">
        //       0
        //     </Label>
        //   ),
        // },
        // {
        //   title: translate("store"),
        //   path: PATH_DASHBOARD.store.root,
        //   icon: ICONS.store,
        //   children: [
        //     {
        //       title: translate("packages"),
        //       path: PATH_DASHBOARD.store.products,
        //     },
        //     // { title: "Material", path: PATH_DASHBOARD.store.material },
        //     { title: translate("events"), path: PATH_DASHBOARD.store.events },
        //     // { title: "Coupons", path: PATH_DASHBOARD.store.coupons },
        //     // { title: "User Reviews", path: PATH_DASHBOARD.store.user_reviews },
        //     // {
        //     //   title: "Assign Subscriptions Categories",
        //     //   path: PATH_DASHBOARD.store.assign_subscriptions_cat,
        //     // },
        //     {
        //       title: translate("assignSubscriptions"),
        //       path: PATH_DASHBOARD.store.assign_subscriptions,
        //     },
        //     // {
        //     //   title: "Business Builder Subscriptions sales",
        //     //   path: PATH_DASHBOARD.store.business_builder_subscriptions,
        //     // },
        //   ],
        // },

        // {
        //   title: translate("userSubscriptions"),
        //   icon: ICONS.user_subscription,
        //   path: PATH_DASHBOARD.activeSubscriptions.root,
        // },
        // {
        //   title: translate("statistics"),
        //   icon: ICONS.analytics,
        //   path: PATH_DASHBOARD.statistics.root,
        // },
      ],
    },

    {
      subheader: translate("General"),
      items: [
        {
          title: translate("settings"),
          path: PATH_DASHBOARD.settings.root,
          icon: ICONS.settings,
          children: [
            // { title: 'Network', path: PATH_DASHBOARD.settings.network },

            // {
            //   title: translate("brand"),
            //   path: PATH_DASHBOARD.settings.brand_settings,
            // },
            // {
            //   title: "Business Builder ",
            //   path: PATH_DASHBOARD.settings.business_settings,
            // },
            {
              title: translate("network"),
              path: PATH_DASHBOARD.settings.network,
            },
            { title: "Withdrawal", path: PATH_DASHBOARD.settings.withdrawal },
            // { title: 'Business Builder Subscription', path: PATH_DASHBOARD.settings.business_builder },
            // { title: 'App Settings', path: PATH_DASHBOARD.settings.app_settings },
            // { title: 'Email Settings', path: PATH_DASHBOARD.settings.email_settings },
            // { title: 'Terms & Conditions', path: PATH_DASHBOARD.settings.terms_conditions },
            // { title: 'User Guidance', path: PATH_DASHBOARD.settings.user_guidance },
            // { title: 'Get Started', path: PATH_DASHBOARD.settings.get_started },
          ],
        },
        {
          title: translate("report"),
          path: PATH_DASHBOARD.report.root,
          icon: ICONS.report,
          children: [
            // {
            //   title: translate("builderSubscription"),
            //   path: PATH_DASHBOARD.report.builder,
            // },
            // {
            //   title: translate("fundCredits"),
            //   path: PATH_DASHBOARD.report.fund_credit,
            // },
            {
              title: translate("Restaurant Users"),
              path: PATH_DASHBOARD.report.restaurant_users,
            },
            {
              title: translate("memberIncome"),
              path: PATH_DASHBOARD.report.member_icome,
            },
            {
              title: translate("topEarners"),
              path: PATH_DASHBOARD.report.top_earners,
            },
            {
              title: translate("payout"),
              path: PATH_DASHBOARD.report.payout_report,
            },
            {
              title: translate("sales"),
              path: PATH_DASHBOARD.report.sales_report,
            },
            {
              title: translate("referral"),
              path: PATH_DASHBOARD.report.referral_report,
            },
            // {
            //   title: translate("builderSubscription"),
            //   path: PATH_DASHBOARD.report.builder_subscription,
            // },
            // {
            //   title: translate("pointHistory"),
            //   path: PATH_DASHBOARD.report.point_history,
            // },
          ],
        },
      ],
    },
  ];
};

export default useNavConfig;

import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { APP_MODE } from "src/config";

import PDFViewer from "../components/pdfViewer";
import auth from "./auth";
import dashboard from "./dashboard";
import main from "./main";
import user from "./user";

const Router = () => {
  const { pathname } = useLocation();
  if (APP_MODE === "MAINTENANCE" && pathname !== "/500") {
    window.location = "/500";
  }

  return useRoutes([
    { ...auth },
    { ...user },
    { ...dashboard },
    ...main,
    { path: "/pdf", element: <PDFViewer /> },
  ]);
};

export default Router;

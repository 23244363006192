import { Grid } from "@mui/material";
import Cards from "./cards";

import i18n from "src/locales/i18n";

const Summary = ({ summary }) => {
  const summaryCard = [
    {
      title: i18n.t("subFinancial.eWallet.balance"),
      icon: "arcticons:priceconverter",
      color: "#d279a6",
      key: "balance",
    },
    {
      title: i18n.t("subFinancial.eWallet.transferOut"),
      icon: "mdi:bank-transfer-out",
      color: "#993366",
      key: "transfer_out",
    },
    {
      title: i18n.t("TransferIn"),
      icon: "mdi:bank-transfer-in",
      color: "#6699ff",
      key: "transfer_in",
    },
    {
      title: i18n.t("subFinancial.eWallet.totalPayout"),
      icon: "fluent:wallet-48-regular",
      color: "#993366",
      key: "total_payout",
    },
    {
      title: i18n.t("subFinancial.eWallet.totalEarnedBonus"),
      icon: "carbon:ibm-cloud-pak-network-automation",
      color: "#999966",
      key: "total_earned_bonus",
    },
    {
      title: i18n.t("SilverHoldAmount"),
      icon: "fa-solid:hand-holding-usd",
      color: "#294180",
      key: "silver_hold_amount",
    },
    {
      title: i18n.t("GoldHoldAmount"),
      icon: "solar:money-bag-bold",
      color: "#56785b",
      key: "gold_hold_amount",
    },
    {
      title: i18n.t("cashback"),
      icon: "teenyicons:money-solid",
      color: "#d279a6",
      key: "cashback",
    },
  ];

  return (
    <>
      {summaryCard.map((props) => (
        <Grid item xs={12} md={3}>
          <Cards {...props} total={summary[props.key]} />
        </Grid>
      ))}
    </>
  );
};

export default Summary;

import { Alert, Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import usePagination from "src/components/pagination/usePagination";
import useLocales from "src/hooks/useLocales";
import { PATH_USER } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import useFetchSummary from "../hooks/useFetchSummary";
import { DataList, FilterCard } from "./components";
import Summary from "./components/summary";

const Index = () => {
    const [filter, setFilter] = useState({
        start_date: "",
        end_date: "",
    });
    const { translate } = useLocales();
    const { summary } = useFetchSummary("ewallet-data");
    const { count, onChange, page, seed, rowStart } = usePagination();
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const fetchData = async (page = 1, filter = {}) => {
        try {
            const { data } = await axiosInstance.get("api/user/ewallet", {
                params: {
                    page,
                    ...filter,
                },
            });
            const { status, data: ewallet } = data;
            if (status) {
                seed(ewallet.last_page, ewallet.from);
                setData(ewallet.data);
                setNewData(ewallet.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData(page, filter);
    }, [page]);

    const total = newData[0] || [];

    return (
        <div>
            <Page title={translate("subFinancial.eWallet.titleEwallet")}>
                <Box sx={{ p: 2 }}>
                    <HeaderBreadcrumbs
                        heading={translate("subFinancial.eWallet.myEwallet")}
                        links={[
                            {
                                name: translate("dashboard"),
                                href: PATH_USER.root,
                            },
                            {
                                name: translate(
                                    "subFinancial.eWallet.myEwallet"
                                ),
                            },
                        ]}
                        action={[
                            <>
                                <Message />
                            </>,
                        ]}
                    />

                    <Grid container spacing={3}>
                        <Summary summary={summary} />

                        <Grid item xs={12} md={12}>
                            <FilterCard
                                total={total}
                                setFilter={setFilter}
                                filter={filter}
                                handleFilter={fetchData}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <DataList
                                total={total}
                                data={data}
                                page={page}
                                count={count}
                                onChange={onChange}
                                rowStart={rowStart}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Page>
        </div>
    );
};

const Message = () => {
    const { translate } = useLocales();
    return (
        <>
            <Stack>
                <Alert
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                    severity="info"
                    sx={{ padding: "0px 16px" }}
                >
                    {translate("SilverHoldAmount")} : <strong>$100</strong>{" "}
                    <br />
                    {translate("GoldHoldAmount")} : <strong>$200</strong>
                </Alert>
            </Stack>
        </>
    );
};

export default Index;

// ----------------------------------------------------------------------

export const company = [
  "Lueilwitz and Sons",
  "Gleichner, Mueller and Tromp",
  "Nikolaus - Leuschke",
  "Hegmann, Kreiger and Bayer",
  "Grimes Inc",
  "Durgan - Murazik",
  "Altenwerth, Medhurst and Roberts",
  "Raynor Group",
];

export const Payment = ["Finpay"];

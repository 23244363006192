import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const BinaryTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binary/index"))
);
const SilverTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/silver/index"))
);
const GoldTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/gold/index"))
);
const BinaryLeg = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binaryLeg/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/sponsor/index"))
);

const Document = Loadable(
  lazy(() => import("src/pages/userSide/tools/document"))
);

const Videos = Loadable(lazy(() => import("src/pages/userSide/tools/videos")));

const tools = {
  path: "tools",
  children: [
    { index: true, element: <Navigate to="documents" /> },
    { path: "documents", element: <Document /> },
    { path: "videos", element: <Videos /> },
  ],
};

export default tools;

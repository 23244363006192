import useAuth from "src/hooks/useAuth";
import { useMemo } from "react";
import { USER_PURCHASE_STATUS } from "src/config";

const SUPER_ADMIN_ID = 1;
const useIsUserInavtive = () => {
  const { user } = useAuth();
  const status = useMemo(() => {
    if (user.id === SUPER_ADMIN_ID) {
      return USER_PURCHASE_STATUS.none;
    }

    switch (user?.purchase_check) {
      case 1: {
        return USER_PURCHASE_STATUS.active;
      }
      case 0: {
        return USER_PURCHASE_STATUS.inactive;
      }
      default: {
        return USER_PURCHASE_STATUS.none;
      }
    }
  }, [user]);
  return status;
};
export default useIsUserInavtive;

import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import { CHAT_HOST_API } from "src/config";
import useAuth from "src/hooks/useAuth";
import Chats from "./chats";
import DownLineUsers from "./donwline-users";
import "./style.css";
import ViewChat from "./viewChat";

export const socket = io(CHAT_HOST_API, {
  autoConnect: false,
  transports: ["websocket", "polling"],
});

const FloatingAction = () => {
  const [showComponentB, setShowComponentB] = useState(null);

  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);

  const close = () => {
    document.querySelector(".bpChatDisplyBox")?.classList?.remove("active");
    closeSearch();
  };

  const onClick = () => {
    document.querySelector(".bpChatDisplyBox")?.classList?.toggle("active");
  };

  const connected = useRef(false);
  console.log(Object.keys(user).length);
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const sessionID = localStorage.getItem("sessionID");
      if (sessionID) {
        socket.auth = { sessionID };
      } else {
        socket.auth = { username: user.username, userID: user.id };
      }

      socket.connect();

      socket.on("session", ({ sessionID, userID }) => {
        socket.auth = { sessionID };
        localStorage.setItem("sessionID", sessionID);
        setCurrentUser(userID);
        socket.userID = userID;
        connected.current = true;
      });
    }
  }, [user]);

  useEffect(() => {
    socket.on("users", (users) => {
      for (let i = 0; i < users.length; i++) {
        if (users[i].notification) onClick();
      }
      setUsers(users);
    });
  }, [socket.connected, socket.userID]);

  const Back = () => {
    setShowComponentB(false);
  };

  useEffect(() => {
    socket.on("user_connected", (connectedUser) => {
      if (parseInt(connectedUser.userID) === user.id) return;
      setUsers((prevState) => {
        const users = [...prevState];
        const userIndex = users.findIndex(
          ({ userID }) => userID === connectedUser.userID
        );
        if (userIndex > -1) {
          const { profile_image } = users[userIndex] || {};

          users.splice(userIndex, 1, { ...connectedUser, profile_image });
          return users;
        }
        return prevState;
      });
    });
  }, [user.id]);

  useEffect(() => {
    socket.on("user_disconnected", (disconnectedUser) => {
      setUsers((prevState) => {
        const temp = [...prevState];
        const index = temp.findIndex(
          ({ userID }) => userID === disconnectedUser.userID
        );

        if (index > -1) {
          temp.splice(index, 1, disconnectedUser);
          return temp;
        }
        return prevState;
      });
    });
  }, []);

  const chatUser = useMemo(() => {
    if (showComponentB) {
      return users.find(({ userID }) => {
        return userID === showComponentB;
      });
    }
  }, [users, showComponentB]);
  useEffect(() => {
    if (!chatUser) {
      socket.on("message_notification", ({ from }) => {
        setUsers((prevState) => {
          const temp = [...prevState];
          const userIndex = temp.findIndex(({ userID }) => userID === from);
          if (userIndex > -1) {
            const updateUser = temp[userIndex];
            new Notification(
              `You have a notification from ${updateUser.username}`
            );
            document.title = `${updateUser.username} Sent you a message`;
            setShowComponentB(from);
            document
              .querySelector(".bpChatDisplyBox")
              ?.classList?.add("active");
            temp.splice(userIndex, 1, { ...updateUser, notification: false });
            return temp;
          }
          return prevState;
        });
      });
    }
  }, [chatUser, users]);

  useEffect(() => {
    if (showComponentB) {
      setUsers((prevState) => {
        const temp = [...prevState];
        const userIndex = temp.findIndex(
          ({ userID }) => userID === showComponentB
        );

        if (userIndex > -1) {
          const updateUser = temp[userIndex];
          temp.splice(userIndex, 1, { ...updateUser, notification: false });
          return temp;
        }
        return prevState;
      });
    }
  }, [showComponentB]);

  useEffect(() => {
    if (showComponentB) {
      socket.emit("open_chat", showComponentB);
    }
  }, [showComponentB]);

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("sessionID");
      socket.disconnect();
      connected.current = false;
    };
  }, []);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const openSearch = () => setShowSearch(true);
  const closeSearch = () => {
    setShowSearch(false);
    setSearchTerm("");
  };

  const totalUnreadCount = useMemo(
    () =>
      users.reduce((acc, curr) => {
        return acc + curr?.unreadCount;
      }, 0),
    [users]
  );

  return (
    <>
      <Paper elevation={3}>
        <div class="bpChatBtn" onClick={onClick}>
          <div style={{ position: "relative" }}>
            <Ternary
              when={totalUnreadCount > 0}
              then={
                <b
                  style={{
                    position: "absolute",
                    height: "24px",
                    width: "24px",
                    background: "red",
                    color: "#fff",
                    borderRadius: "50%",
                    left: "20px",
                    top: "-10px",
                    fontSize: "11px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {totalUnreadCount || 0}
                </b>
              }
            />
            <img
              src="/assets/chat/red_chat.png"
              height="36px"
              width="50px"
              class="bpChatshow show"
              alt="best mlm software"
            />
          </div>
        </div>
        <div class="bpChatDisplyBox">
          <ul className="chatIcons">
            <li class="bpChatBtnIcon" onClick={close}>
              <Iconify color="#2f4252" icon={"ic:round-minus"} />
            </li>
          </ul>
          <div class="bpChatDisplyHeaderBox"></div>
          <div class="bpChatDisplyBodyBox">
            <Ternary
              when={showComponentB}
              then={
                <ViewChat
                  chatUser={chatUser}
                  currentUser={currentUser}
                  socket={socket}
                  showComponentB={showComponentB}
                  Back={Back}
                />
              }
              otherwise={
                <>
                  <Box sx={{ p: 2, borderRadius: 4 }}>
                    <TextField
                      onFocus={openSearch}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                      fullWidth
                      label="Search Users"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                if (showSearch) {
                                  closeSearch();
                                }
                              }}
                              size="small"
                            >
                              <Iconify
                                icon={
                                  showSearch ? "ci:close-md" : "line-md:search"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Ternary
                    when={showSearch}
                    then={
                      <DownLineUsers
                        closeSearch={closeSearch}
                        searchTerm={searchTerm}
                        setShowComponentB={setShowComponentB}
                        socket={socket}
                        setGlobalUsers={setUsers}
                      />
                    }
                    otherwise={
                      <Chats
                        users={users}
                        setShowComponentB={setShowComponentB}
                      />
                    }
                  />
                </>
              }
            />
          </div>
        </div>
      </Paper>
    </>
  );
};

export default FloatingAction;

import { Box, Grid } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_USER } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import Cards from "./cards";
import DataList from "./components/dataTable";
import FilterCard from "./filterCard";
import PayNow from "./payNow";
import useLocales from "src/hooks/useLocales";

const _data = [
  {
    title: "Balance",
    icon: "arcticons:priceconverter",
    color: "#d279a6",
    key: "balance",
  },
  {
    title: "Transfer Out",
    icon: "bx:transfer-alt",
    color: "#6699ff",
    key: "transfer_out",
  },
  {
    title: "Credited by admin",
    icon: "material-symbols:admin-panel-settings",
    color: "#26a69a",
    key: "credited_by_admin",
  },
];

const Index = () => {
  const { translate } = useLocales();
  const summary = useFetchSummary("deposit-wallet-data");
  return (
    <div>
      <Page title={translate("subFinancial.depositWallet.depositWalletTitle")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("subFinancial.depositWallet.depositWallet")}
            links={[
              { name: translate("dashboard"), href: PATH_USER.root },
              { name: translate("subFinancial.depositWallet.depositWallet") },
            ]}
          />

          <Grid container spacing={3}>
            {_data.map((props) => (
              <Grid item xs={12} md={4}>
                <Cards {...props} total={summary[props.key]} />
              </Grid>
            ))}
            <Grid item xs={12} md={12}>
              <PayNow />
            </Grid>

            <Grid item xs={12} md={12}>
              <FilterCard />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <DataList />
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;

import { Box, Card, Grid } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useLocales from "src/hooks/useLocales";
import i18n from "src/locales/i18n";
import { PATH_DASHBOARD } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import Cards from "./cards";
import DataList from "./dataList";
import useFundTransfer from "./hooks/useFundTransfer";
import SendForm from "./sendForm";

const Index = () => {
    const _data = [
        {
            title: i18n.t("subFinancial.fundTrasfer.balance"),
            color: "#795548",
            icon: "entypo:wallet",
            key: "ewallet_balance",
        },
    ];

    const { translate } = useLocales();
    const { refetch, ...rest } = useFundTransfer();
    const { summary, fetchDataSummary: fetchData } =
        useFetchSummary("fund-transfer-data");

    return (
        <>
            <div>
                <Page title={translate("subFinancial.fundTrasfer.fundTitle")}>
                    <Box sx={{ p: 2 }}>
                        <HeaderBreadcrumbs
                            heading={translate(
                                "subFinancial.fundTrasfer.fundTransfer"
                            )}
                            links={[
                                {
                                    name: translate("dashboard"),
                                    href: PATH_DASHBOARD.root,
                                },
                                {
                                    name: translate(
                                        "subFinancial.fundTrasfer.fundTransfer"
                                    ),
                                },
                            ]}
                        />
                        <Card sx={{ p: 2 }}>
                            <Grid container spacing={3}>
                                {_data.map((props) => (
                                    <Grid item xs={12} md={4}>
                                        <Cards
                                            {...props}
                                            total={summary[props.key]}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <SendForm
                                    fetchData={fetchData}
                                    refetch={refetch}
                                />
                            </Grid>
                        </Card>
                        <Grid item xs={12} md={12}>
                            <DataList {...rest} />
                        </Grid>
                    </Box>
                </Page>
            </div>
        </>
    );
};

export default Index;

import React from "react";
// @mui
import DatePicker from "@mui/lab/DatePicker";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";
// components
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";

const FilterCard = () => {
  const { translate } = useLocales();
  const methods = useForm();
  const onSubmit = methods.handleSubmit((inputData) => {});
  const {
    control,
    formState: { isSubmitting },
  } = methods;
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box>
          <Typography variant="subtitle2">{(translate("subFinancial.eWallet.walletHistory"))}</Typography>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(4, 1fr)",
                },
              }}
            >
              <Controller
                control={control}
                name="start_date"
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={(translate("subFinancial.eWallet.picStartDate"))}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="end_date"
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={(translate("subFinancial.eWallet.picEndDate"))}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />

              <RHFTextField name="userName" label={(translate("subFinancial.eWallet.username"))} />
              <RHFSelect name="amountType" label={(translate("subFinancial.eWallet.amountType"))}>
                <option value="all">{(translate("subFinancial.depositWallet.all"))} </option>
                <option value="released">{(translate("subFinancial.depositWallet.released"))}</option>
                <option value="pending">{(translate("subFinancial.depositWallet.pending"))}</option>
                <option value="failed">{(translate("subFinancial.depositWallet.failed"))} </option>
                <option value="rejected">{(translate("subFinancial.depositWallet.reject"))}</option>
                <option value="finished">{(translate("subFinancial.depositWallet.finished"))} </option>
                <option value="approved">{(translate("subFinancial.depositWallet.approved"))}</option>
                <option value="fund_transfer">{(translate("subFinancial.depositWallet.fundTransfer"))} </option>
                <option value="plan_purchase">{(translate("subFinancial.depositWallet.planPurchase"))} </option>
                <option value="fund_transfer">{(translate("subFinancial.depositWallet.fundTransfer"))} </option>
                <option value="self_transfer">{(translate("subFinancial.depositWallet.selfTransfer"))} </option>
                <option value="referral_bonus">{(translate("subFinancial.depositWallet.referalBonus"))} </option>
                <option value="achievement_bonus">{(translate("subFinancial.depositWallet.achivementBonus"))} </option>
                <option value="first_order_bonus">{(translate("subFinancial.depositWallet.firstOrderBonus"))} </option>
                <option value="binary_bonus">{(translate("subFinancial.depositWallet.binaryBonus"))} </option>
                <option value="credited_by_admin">{(translate("subFinancial.depositWallet.createdByAdmin"))} </option>
                <option value="deducted_by_admin">{(translate("subFinancial.depositWallet.deductedByAdmin"))} </option>
              </RHFSelect>
            </Box>
            <Box mt={2} sx={{ float: "right" }}>
              <Button variant="contained">{translate("getReport")}</Button>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </>
  );
};

export default FilterCard;

import { useEffect } from "react";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ChartStyle } from "./components/chart";
import Settings from "./components/settings";
import Ternary from "./components/ternary";

import FloatingAction from "./components/floating-action";
import useAuth from "./hooks/useAuth";
import Router from "./routes";
import ThemeProvider from "./theme";

export default function App() {
  const { getUser, user } = useAuth();
  useEffect(() => {
    if (user && !Object.keys(user).length) {
      getUser();
      return;
    }
  }, [user]);
  const isImpersonate = Boolean(localStorage.getItem("isImpersonate"));

  return (
    <>
      <ThemeProvider>
        <ThemeColorPresets>
          {/* <ThemeLocalization> */}
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                {/* <Ternary
                  when={Object.keys(user || {}).length > 0 && !isImpersonate}
                  then={<FloatingAction />}
                /> */}
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
          {/* </ThemeLocalization> */}
        </ThemeColorPresets>
      </ThemeProvider>
    </>
  );
}

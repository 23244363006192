import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const ProductCategories = Loadable(
  lazy(() => import("src/pages/store/productCategories/index"))
);
const Products = Loadable(lazy(() => import("src/pages/store/product/index")));
const MaterialCategories = Loadable(
  lazy(() => import("src/pages/store/materialCategories/category/index"))
);
const Material = Loadable(lazy(() => import("src/pages/store/material/index")));
const MaterialAdd = Loadable(
  lazy(() => import("src/pages/store/material/materialAdd/index"))
);
const MaterialView = Loadable(
  lazy(() => import("src/pages/store/material/materialView"))
);
const Events = Loadable(lazy(() => import("src/pages/store/events/index")));
const EventsAdd = Loadable(
  lazy(() => import("src/pages/store/events/eventAdd"))
);
const EventsEdit = Loadable(
  lazy(() => import("src/pages/store/events/eventEdit"))
);
const Coupons = Loadable(lazy(() => import("src/pages/store/coupons/index")));
const CouponsAdd = Loadable(
  lazy(() => import("src/pages/store/coupons/couponAdd"))
);
const CouponsEdit = Loadable(
  lazy(() => import("src/pages/store/coupons/couponEdit"))
);
const UserReviews = Loadable(
  lazy(() => import("src/pages/store/userReviews/index"))
);
const UserReviewsAdd = Loadable(
  lazy(() => import("src/pages/store/userReviews/reviewAdd"))
);
const UserReviewsView = Loadable(
  lazy(() => import("src/pages/store/userReviews/reviewView"))
);
const AssignSubscriptionsCat = Loadable(
  lazy(() => import("src/pages/store/assignSubscriptionsCat/index"))
);
const AssignSubscriptionsAddProduct = Loadable(
  lazy(() => import("src/pages/store/assignSubscriptions/addProduct/index"))
);
const AssignSubscriptions = Loadable(
  lazy(() => import("src/pages/store/assignSubscriptions/index"))
);
const BusinessBuilderSubscriptions = Loadable(
  lazy(() => import("src/pages/store/businessBuilderSubscriptions/index"))
);
const ProductVideo = Loadable(
  lazy(() => import("src/pages/store/product/Video"))
);
const DocumentViewEdit = Loadable(
  lazy(() => import("src/pages/store/product/document/index"))
);
const ProductAccess = Loadable(
  lazy(() => import("src/pages/store/product/productAccess"))
);
const ProductQuestions = Loadable(
  lazy(() => import("src/pages/store/product/Questions/Main/index"))
);
const ProductEdit = Loadable(
  lazy(() => import("src/pages/store/product/productEdit"))
);
const ProductAdd = Loadable(
  lazy(() => import("src/pages/store/product/productAdd"))
);
const ProductCombo = Loadable(
  lazy(() => import("src/pages/store/product/Combo"))
);
const SampleDocument = Loadable(
  lazy(() => import("src/pages/store/product/sampleDocument"))
);

const store = {
  path: "store",
  children: [
    { element: <Navigate to="/404" replace />, index: true },
    {
      path: "assign_subscriptions_cat",
      element: <AssignSubscriptionsCat />,
    },
    { path: "assign_subscriptions", element: <AssignSubscriptions /> },
    {
      path: "assign_subscriptions/add_product",
      element: <AssignSubscriptionsAddProduct />,
    },
    {
      path: "business_builder_subscriptions",
      element: <BusinessBuilderSubscriptions />,
    },
    {
      path: "reviews",
      children: [
        { index: true, element: <UserReviews /> },
        { path: "add", element: <UserReviewsAdd /> },
        { path: ":rid", element: <UserReviewsView /> },
      ],
    },
    {
      path: "coupons",
      children: [
        { index: true, element: <Coupons /> },
        { path: "add", element: <CouponsAdd /> },
        { path: ":cid", element: <CouponsEdit /> },
      ],
    },
    {
      path: "events",
      children: [
        { index: true, element: <Events /> },
        { path: "add", element: <EventsAdd /> },
        { path: ":eid", element: <EventsEdit /> },
      ],
    },
    {
      path: "materials",
      children: [
        { index: true, element: <Material /> },
        {
          path: "categories",
          element: <MaterialCategories />,
        },
        { path: "add", element: <MaterialAdd /> },
        {
          path: ":id",
          children: [
            { index: true, element: <MaterialView /> },
            { path: "add", element: <MaterialAdd /> },
          ],
        },
      ],
    },
    {
      path: "products",
      children: [
        { element: <Products />, index: true },
        { path: "categories", element: <ProductCategories /> },
        { path: "add", element: <ProductAdd /> },
        { path: "combo", element: <ProductCombo /> },
        {
          path: ":pid",
          children: [
            { element: <ProductEdit />, index: true },

            { path: "video", element: <ProductVideo /> },
            {
              path: "document",
              element: <DocumentViewEdit />,
            },
            { path: "access", element: <ProductAccess /> },
            {
              path: "questions",
              element: <ProductQuestions />,
            },
            { path: "sample", element: <SampleDocument /> },
          ],
        },
      ],
    },
  ],
};

export default store;

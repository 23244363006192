import React, { useState, useEffect } from "react";
import { capitalCase } from "change-case";
import DatePicker from "@mui/lab/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
// import useUsersList from "src/components/UsersAutoComplete/hooks/useUsersList";
import useUsersList from "../hooks/userFetch";
import * as XLSX from "xlsx";
import Iconify from "src/components/Iconify";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "src/utils/axios";

const DataFilter = ({ total, handleFilter, setFilter, filter }) => {
  const { translate } = useLocales();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { start_date, end_date, user_id, payment_type, type } = filter;
  const usersList = useUsersList();
  const selected =
    options.find(({ name: uid }) => uid === payment_type) || null;
  const onSubmit = () => {
    handleFilter(1, {
      start_date,
      end_date,
      user_id,
      payment_type,
      type,
    });
  };
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [loading, setLoading] = useState(false);

  const handleClickExcel = async (page = 1, filter = {}) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get("api/user/ewallet", {
        params: {
          page,
          excel: "excel",
          ...filter,
        },
      });

      if (status) {
        setLoading(false);
        const { data_unpaginated, total_amount } = data;
        const newArray = data_unpaginated?.map((item, i) => ({
          "No.": i + 1,
          "From User": item.from_user?.username,
          "Amount Type": capitalCase(item.payment_type),
          Amount: item.total_amount,
          "Hold Amount": item.hold_amount,
          "Release Amount": item.withdrawal_amount,
          "Hold Type":
            item.stage === "2"
              ? "Silver"
              : null || item.stage === "3"
              ? "Gold"
              : null,
          "Payment Status":
            item.hold_amount > 0 && item.withdrawal_amount > 0
              ? "Partially Released"
              : capitalCase(item.status),
          Date: new Date(item?.created_at).toLocaleDateString("en-GB"),
        }));

        const worksheet = XLSX.utils.json_to_sheet(newArray);
        const dummyRow = {
          Amount: `Total :       ${
            newArray.length === 0 ? "0" : total?.total_amount_sum
          }`,
        };
        XLSX.utils.sheet_add_json(worksheet, [dummyRow], {
          skipHeader: true,
          origin: -1,
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);
        XLSX.writeFile(workbook, "Ewallet history.xlsx");
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div>
      <Card sx={{ p: 3 }}>
        <Grid item xs={12} md={12}>
          {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
          <Box
            sx={{
              display: "grid",
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(5, 1fr)",
              },
            }}
          >
            <DatePicker
              onChange={(v) => (
                setFilter({
                  ...filter,
                  start_date: new Date(v).toLocaleDateString("en-GB"),
                }),
                setStartDate(v)
              )}
              inputFormat="dd/MM/yyyy"
              value={startDate}
              renderInput={(params) => (
                <TextField
                  name="start_date"
                  {...params}
                  error={null}
                  fullWidth
                  label={translate("StartDate")}
                  size="small"
                />
              )}
            />
            <DatePicker
              onChange={(v) => {
                return (
                  setFilter({
                    ...filter,
                    end_date: new Date(v).toLocaleDateString("en-GB"),
                  }),
                  setEndDate(v)
                );
              }}
              inputFormat="dd/MM/yyyy"
              value={endDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={null}
                  fullWidth
                  size="small"
                  label={translate("EndDate")}
                />
              )}
            />
            {/* <Autocomplete
              onChange={(_, v) => setFilter({ ...filter, user_id: v.user_id })}
              value={user_id}
              options={usersList}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label={translate("UserName")}
                />
              )}
            /> */}
            {/* <Type /> */}
            <Autocomplete
              onChange={(_, v) => {
                if (v) setFilter({ ...filter, payment_type: v.name });
                else {
                  setFilter({ ...filter, payment_type: null });
                }
              }}
              value={selected}
              options={options}
              getOptionLabel={(option) => option.type}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label={translate("AmountType")}
                />
              )}
            />

            <Button size="small" variant="contained" onClick={onSubmit}>
              {translate("getReport")}
            </Button>

            <LoadingButton
              loading={loading}
              onClick={() => handleClickExcel(1, filter)}
              variant="outlined"
              size="small"
              startIcon={<Iconify icon={"ri:file-excel-2-line"} />}
              name="excel"
            >
              Excel
            </LoadingButton>
          </Box>
          {/* </FormProvider> */}
        </Grid>
      </Card>
    </div>
  );
};
const options = [
  // { name: "all", type: "All" },
  { name: "fund_transfer", type: "Fund Transfer" },
  { name: "referral_commission", type: "Referral Commission" },
  { name: "credited_by_admin", type: "Credited by Admin" },
  { name: "deducted_by_admin", type: "Deducted by Admin" },
  { name: "bronze_level_", type: "Bronze level bonus" },
  { name: "silver_level_", type: "Silver level bonus" },
  { name: "gold_level_", type: "Gold level bonus" },
  {
    name: "restaurant_sponsoring_commission",
    type: "Restaurant Sponsoring Commission",
  },
  { name: "booster_buyer_commission", type: "Booster Buyer Commission" },
  {
    name: "booster_level_",
    type: "Booster Level Commissions",
  },
  {
    name: "restaurant_bonus",
    type: "Restaurant Bonus",
  },
];

export default DataFilter;

import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const EcommerceShop = Loadable(
  lazy(() => import("src/pages/dashboard/EcommerceShop"))
);

const EcommerceProductDetails = Loadable(
  lazy(() => import("src/pages/dashboard/EcommerceProductDetails"))
);

const EcommerceProductList = Loadable(
  lazy(() => import("src/pages/dashboard/EcommerceProductList"))
);

const EcommerceProductCreate = Loadable(
  lazy(() => import("src/pages/dashboard/EcommerceProductCreate"))
);

const EcommerceCheckout = Loadable(
  lazy(() => import("src/pages/dashboard/EcommerceCheckout"))
);

const eCommerce = {
  path: "e-commerce",
  children: [
    {
      element: <Navigate to="/dashboard/e-commerce/shop" replace />,
      index: true,
    },
    { path: "shop", element: <EcommerceShop /> },
    { path: "product/:name", element: <EcommerceProductDetails /> },
    { path: "list", element: <EcommerceProductList /> },
    { path: "product/new", element: <EcommerceProductCreate /> },
    { path: "product/:name/edit", element: <EcommerceProductCreate /> },
    { path: "checkout", element: <EcommerceCheckout /> },
  ],
};

export default eCommerce;

import { Box, Card, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";

const Cards = ({ title, color, total, icon }) => {
    const { translate } = useLocales();

    return (
        <Tooltip
            title={
                icon === "teenyicons:money-solid" ? translate("ewalletMsg") : ""
            }
        >
            <Card sx={{ display: "flex", alignItems: "center", p: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography sx={{ fontSize: "13px" }} variant="subtitle2">
                        {title}
                    </Typography>

                    <Typography variant="h4">$ {total}</Typography>
                </Box>

                <Box>
                    <Iconify color={color} width={60} height={60} icon={icon} />
                </Box>
            </Card>
        </Tooltip>
    );
};

Cards.propTypes = {
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
};

export default Cards;

import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import useErrors from "src/hooks/useErrors";
import useLocales from "src/hooks/useLocales";
import fetchUser from "src/utils/fetchUser";
import { mixed, number, object, string } from "yup";

const useFundForm = (refetch, fetchData) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const defaultValues = {
    wallet: "ewallet",
    amount: "",
    user_id: "",
    note: "",
    type: "",
  };

  const schema = object().shape({
    wallet: mixed()
      .oneOf(["ewallet", "deposit_wallet"], "Please choose a wallet")
      .required("Please choose a from wallet"),

    amount: number()
      .min(1, translate("minNumber"))
      .typeError(translate("validNum"))
      .required(translate("amountChoose")),
    type: string().required(translate("typeReq")),
    user_id: string()
      .when("type", {
        is: (type) => type === "other",
        then: string().required(translate("typeReq")),
      })
      .nullable(),
  });
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const onSubmit = async (inputData) => {
    const reqData = new FormData();
    Object.entries(inputData).forEach(([key, value]) =>
      reqData.append(key, value)
    );
    try {
      const { message, status } = await (
        await fetchUser.post("fund-transfer", reqData)
      ).data;

      if (status) {
        enqueueSnackbar(message);
        methods.reset(defaultValues);
        refetch();
        fetchData();
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      console.error(err);
    }
  };

  return { methods, onSubmit: methods.handleSubmit(onSubmit) };
};

export default useFundForm;

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useFundTransfer from "./hooks/useFundTransfer";

const DataList = ({ data, rowStart }) => {
  const { translate } = useLocales();
  // const { data, rowStart, ...rest } = useFundTransfer();

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate("subFinancial.eWallet.no")}</TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.toUser")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.paymentType")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.walletType")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.amount")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.date")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.note")}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundTrasfer.paymentStatus")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {data?.length > 0 ? (
                <TableBody>
                  {data.map(
                    (
                      {
                        payable_amount,
                        payment_type,
                        wallet_type,
                        created_at,
                        note,
                        payment_status,
                        from_user,
                      },
                      i
                    ) => {
                      return (
                        <TableRow>
                          <TableCell>{i + rowStart}</TableCell>
                          <TableCell>{from_user?.username}</TableCell>
                          <TableCell>{payment_type}</TableCell>
                          <TableCell>{wallet_type}</TableCell>
                          <TableCell>{payable_amount}</TableCell>
                          <TableCell>
                            {new Date(created_at)?.toLocaleDateString("en-GB")}
                          </TableCell>
                          <TableCell>{note}</TableCell>
                          <TableCell>{payment_status}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    {translate("NoDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

      {/* <PaginationButtons {...rest} /> */}
    </>
  );
};

export default DataList;

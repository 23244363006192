import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { capitalCase, noCase } from "change-case";
import moment from "moment";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";

const stripDateAndFormat = (date = "") =>
  moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");

const DataList = ({ total, data, rowStart, page, onChange, count }) => {
  const { translate } = useLocales();
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate("subFinancial.eWallet.no")}</TableCell>
                  <TableCell> {translate("FromUser")} </TableCell>
                  <TableCell>
                    {translate("subFinancial.eWallet.amountType")}{" "}
                  </TableCell>
                  <TableCell>
                    {translate("subFinancial.eWallet.amount")}
                  </TableCell>
                  <TableCell> {translate("HoldAmount")} </TableCell>
                  <TableCell> {translate("ReleaseAmount")} </TableCell>
                  <TableCell>{translate("HoldType")} </TableCell>
                  <TableCell>
                    {translate("subFinancial.fundCredit.notes")}
                  </TableCell>
                  <TableCell>{translate("paymentStatus")}</TableCell>
                  <TableCell>
                    {translate("subFinancial.eWallet.date")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    {translate("NoDataAvailable")}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data.map((item, i) => {
                  return (
                    <TableRow>
                      <TableCell>{i + rowStart}</TableCell>
                      <TableCell>{item.from_user?.username}</TableCell>
                      <TableCell>{capitalCase(item.payment_type)}</TableCell>
                      <TableCell>{item.total_amount}</TableCell>

                      <TableCell>{item.hold_amount}</TableCell>

                      <TableCell>{item.withdrawal_amount}</TableCell>

                      <TableCell>
                        {item.stage === "2"
                          ? "Silver"
                          : null || item.stage === "3"
                          ? "Gold"
                          : null}
                      </TableCell>
                      <TableCell>{item.note}</TableCell>
                      <TableCell>
                        {item.hold_amount > 0 && item.withdrawal_amount > 0
                          ? "Partially Released"
                          : capitalCase(item.status)}
                      </TableCell>

                      <TableCell>
                        {stripDateAndFormat(item.created_at)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "right", p: 2 }}>
          {translate("Total")} :{" "}
          {data.length === 0 ? "0" : total?.total_amount_sum}
        </Box>
      </Card>
      <PaginationButtons count={count} onChange={onChange} page={page} />
    </>
  );
};

export default DataList;

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Box,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

export default function RegisterForm() {
  const { register } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { uname } = useParams();

  const passwordRegex = /^\S+$/;
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [payment_type, setPayment] = useState("stripe");
  const usernameRegex = /^[\w-]*$/;
  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is requried"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),

    username: Yup.string()
      .required("User Name is required")
      .trim()
      .matches(
        usernameRegex,
        " This field cannot contain white space and special character"
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New Password is required")
      .matches(passwordRegex, "This field cannot contain white space"),
    repassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password must match"
    ),
    referral: Yup.string().required("Referral Id is required"),
    checkvalue: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });

  const defaultValues = {
    email: "",
    username: "",
    password: "",
    repassword: "",
    referral: "sparissimo",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await register(
        data.first_name,
        data.last_name,
        data.email,
        data.username,
        data.password,
        data.referral
        // payment_type
      );
      // enqueueSnackbar("registered success Welcome !!!");
    } catch (error) {
      console.error(error);
      Object.entries(error.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );
      console.log(error.errors);
      setError("afterSubmit", {
        message: error.message.toString(),
      });
    }
  };

  useEffect(() => {
    if (uname) {
      methods.setValue("referral", uname);
    }
  }, [uname]);

  // const [value, setValue] = useState("coinpayment");
  const handleChange = (e) => {
    setPayment(e.target.value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Typography variant="h5">Register with us now!</Typography>
        <RHFTextField name="first_name" size="medium" label="First Name" />
        <RHFTextField name="last_name" size="medium" label="Last Name" />

        <RHFTextField
          name="email"
          size="medium"
          // label={<div style={{ fontSize: "14px" }}>Email address</div>}
          label="Email address"
        />
        <RHFTextField
          name="username"
          size="medium"
          // label={<div style={{ fontSize: "14px" }}>User Name</div>}
          label="User Name"
        />
        <RHFTextField
          size="medium"
          name="password"
          // label={<div style={{ fontSize: "14px" }}>Password</div>}
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          size="medium"
          name="repassword"
          label="Re Enter Password"
          // label={<div style={{ fontSize: "14px" }}>Re Enter Password</div>}
          type={showRePassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowRePassword(!showRePassword)}
                >
                  <Iconify
                    icon={showRePassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <div style={{ marginTop: "-5px", marginBottom: "10px" }}>
          {" "}
          <Box>
            <Iconify
              margin="-5px -7px -43px -1px"
              color="#837cef"
              icon={"flat-color-icons:info"}
              width={20}
              height={20}
            />
            <div
              style={{
                fontSize: "13px",
                margin: "0px 0 0px 25px",
                color: "000000c2",
              }}
            >
              Registration Fee : <span style={{ fontWeight: "bold" }}>$50</span>{" "}
            </div>
          </Box>
        </div> */}

        {/* <Box
          style={{
            borderRadius: "7px",
            border: "solid 1px #ccc",
            padding: "15px 20px 0px",
            marginTop: "20px",
            marginBottom: "5px",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              margin: "-34px 0 0px 0px",
              backgroundColor: "#fff",
              width: "fit-content",
              padding: "7px",
              color: "#DB5338",
              fontWeight: "100",
              fontSize: "13px",
            }}
          >
            Payment Method
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={payment_type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="stripe"
              control={<Radio size="small" />}
              label={<div style={{ fontSize: "13px" }}></div>}
            />
            <Iconify
              margin=" -5px 25px 0px -15px"
              color="#837cef"
              icon={"fluent-emoji:credit-card"}
              width={35}
              height={34}
            />
            <FormControlLabel
              id="radiolabel"
              value="paypal"
              control={<Radio size="small" />}
              label={<div style={{ fontSize: "13px" }}></div>}
            />
            <Iconify
              margin=" 0px 25px 0px -15px"
              color="#2962ff"
              icon={"fa6-brands:cc-paypal"}
              width={30}
              height={34}
            />
            <FormControlLabel
              id="radiolabel"
              value="crypto"
              control={<Radio size="small" />}
              label={<div style={{ fontSize: "13px" }}></div>}
            />
            <Iconify
              margin=" 0px 25px 0px -15px"
              color="#FFC000"
              icon={"cryptocurrency-color:btc"}
              width={30}
              height={34}
            />
          </RadioGroup>
        </Box> */}
        <RHFTextField
          // label={<div style={{ fontSize: "14px" }}>Referral ID</div>}
          label="Referral ID"
          size="medium"
          name="referral"
        />

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 0 }}
          >
         
            <Link underline="always" color="text.primary" href="#">
              Terms of Service
            </Link>
            &nbsp; & &nbsp;
            <Link underline="always" color="text.primary" href="#">
              Privacy Policy
            </Link>
          </Typography>
        </Stack> */}
        {/* <FormGroup style={{ margin: "0px 0px 5px 0px" }}>
          <FormControlLabel
            style={{ margin: "5px 0px 0px 0px" }}
            control={<Checkbox name="checkvalue" required />}
            label=""
          />

          <Typography
            style={{
              margin: "-32px 2px 0px 32px",
              width: "fit-content",
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            By registering I accept the{" "}
            <a href="https://www.sparissimo.com/agb" target="_blank">
              terms
            </a>{" "}
            and conditions{" "}
            <a href="https://www.sparissimo.com/datenschutz" target="_blank">
              data protection
            </a>{" "}
            conditions,and I agree that I am automatically registered on the Api
            community and shopping portals.
          </Typography>
        </FormGroup> */}

        {/* <Formik
          initialValues={{
            checkvalue: false,
          }}
          validationSchema={RegisterSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <div>
                <label>
                  <Field type="checkbox" name="checkvalue" />
                  <Typography
                    style={{
                      margin: "-33px 35px 0px",
                      // width: "fit-content",
                      fontSize: 15,
                    }}
                  >
                    By registering I accept the terms and conditions data
                    protection conditions,and I agree that I am automatically
                    registered on the Sparissimo community and shopping portals.
                  </Typography>
                </label>
                {errors.checkvalue && <p>{errors.checkvalue}</p>}
              </div>
            </Form>
          )}
        </Formik> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

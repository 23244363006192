import { Box, Stack, Typography } from "@mui/material";

const Message = () => (
  <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Sign in to Your Account
      </Typography>
      <Typography sx={{ color: "text.secondary" }}></Typography>
    </Box>
  </Stack>
);

export default Message;

import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const Wrapper = Loadable(
    lazy(() => import("src/pages/userSide/profile/index"))
);
const Profile = Loadable(
    lazy(() => import("src/pages/userSide/profile/subPages/profile/index"))
);

const Settings = Loadable(
    lazy(() =>
        import("src/pages/userSide/profile/subPages/accountSettings/index")
    )
);

const Referrals = Loadable(
    lazy(() => import("src/pages/userSide/profile/subPages/referrals/index"))
);
const Email = Loadable(
    lazy(() => import("src/pages/userSide/profile/subPages/email/index"))
);

const Notes = Loadable(
    lazy(() => import("src/pages/userSide/profile/subPages/notes/index"))
);

const Edit = Loadable(
    lazy(() => import("src/pages/userSide/profile/subPages/edit/index"))
);

const profile = {
    path: "profile",
    element: <Wrapper />,
    children: [
        { index: true, element: <Navigate to="general" /> },
        { path: "general", element: <Profile /> },
        { path: "settings", element: <Settings /> },
        { path: "referrals", element: <Referrals /> },
        { path: "email", element: <Email /> },
        { path: "notes", element: <Notes /> },
        { path: "edit", element: <Edit /> },
    ],
};

export default profile;

import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import { PATH_AUTH } from "src/routes/paths";
import imagetwo from "src/images/imagetwo.png";
import imagethree from "src/images/imgthree.png";
import imagefour from "src/images/image four.png";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import "./css/style.css";

import Apiwhite from "src/images/apiwhite.png";
import IntroIMG from "src/images/introImg.png";
import imageone from "src/images/imageone.png";
import LogoRegister from "src/layouts/shared/navbar/asssets/sparissimoapi.png";
import RegisterForm from "src/sections/auth/register/RegisterForm";

const Index = () => {
  const { user } = useAuth();
  const { uname } = useParams();
  const isLoggedIn = !user;
  const smUp = useResponsive("up", "sm");
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 3,
        }}
      >
        <RegisterForm />
      </Paper>
    </Box>
  );
  return (
    <>
      <body>
        <section className="bannerContainer">
          <div className="container bannerAlgmnt">
            <div className="row alignceneter">
              <div className="col-lg-5 col-md-6">
                <div className="rgstrFormBox">
                  <RegisterForm />
                  <div className="clearfix"></div>
                  {smUp && (
                    <Typography
                      variant="body2"
                      sx={{ mt: { md: -2 } }}
                      style={{
                        marginTop: "5px",
                        fontSize: "13px",
                      }}
                    >
                      Already have an account&nbsp;? &nbsp;&nbsp;
                      <Link
                        className="Linktext"
                        component={RouterLink}
                        to={PATH_AUTH.login}
                      >
                        Login
                      </Link>
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================================================================== */}
        <section className="bannersection">
          <div className="container">
            <div className="BoxSectionamount">
              <Stack
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1.5rem",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: "150",
                }}
                spacing={1}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 1,
                    columnGap: 1,
                    gridTemplateColumns: {
                      md: "repeat(4,1fr)",
                    },
                  }}
                >
                  <Stack>
                    <h2>25K</h2>
                    <p>Members from europe</p>
                  </Stack>

                  <Stack>
                    <h2>#18</h2>
                    <p>Years of online marketing experience</p>
                  </Stack>
                  <Stack>
                    <h2>2500</h2>
                    <p>company affiliated</p>
                  </Stack>
                  <Stack>
                    <h2>50 USD</h2>
                    <p>One time fee</p>
                  </Stack>
                </Box>
              </Stack>
            </div>
          </div>
        </section>

        <section className="bannersection">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-md-8 col-lg-7">
                <div
                  className="videoSection"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <img
                    src={IntroIMG}
                    width="610"
                    height="564"
                    alt="Arbitmine"
                  />
                  <div className="videoBtnBox">
                    <i class="ri-play-line"></i>
                  </div>
                </div>
                <div className="modal" id="myModal">
                  <div className="modal-dialog videoPopup">
                    <div className="modal-content">
                      <b class="close" data-dismiss="modal">
                        <i class="ri-close-fill"></i>
                      </b>

                      <div className="modal-body">
                        <video width="100%" controls>
                          <source
                            src="https://server.apibusiness.online/api.mp4"
                            type="video/mp4"
                          />
                          <source
                            src="https://server.apibusiness.online/api.mp4"
                            type="video/ogg"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr">We help you </span> to
                  build&nbsp;
                  <span className="primryColr">your business!</span>
                </h2>

                <p className="bannrcaptn">
                  Our company is dedicated to helping every business and
                  individual to generate passive income with a customized online
                  marketing plan and optimization.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> No empty </span> promises
                </h2>

                <p className="textCaption">
                  With us you can see in real time how you generate income ! You
                  have the opportunity to be there right from the start !
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imageone}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagetwo}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> Experts from </span> the online
                  marketing industry{" "}
                </h2>

                <p className="textCaption">
                  We are at your side with our many years of online marketing
                  experience.You can sit back and watch us work for you in the
                  background!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> Automatic </span> passive income
                </h2>

                <p className="textCaption">
                  Through our existing company, you generate income every day
                  through purchases,takeaway and delivery!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagethree}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagefour}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr">No more </span>investment losses{" "}
                </h2>

                <p className="textCaption">
                  With us, you can't lose your investment, guaranteed, because
                  we're involved in our customers' daily online sales!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* ============================================================ */}
        <section className="sectionpadding">
          <div className="container">
            <div className="row  alignceneter">
              <div className="col-lg-6 col-md-5">
                <h2 className="titlemain">
                  <span className="primryColr">We're </span>Accomplished In{" "}
                </h2>

                {/* <h4 className="titlemainSub">Loaram Ipsumis a dummy text</h4> */}
                <p className="bannrcaptn">
                  With our selection of expert-recommended passive income
                  opportunities, we can help you unlock your financial potential
                  and make your financial dreams a reality. Our top-notch
                  passive income businesses offer a variety of strategies that
                  allow you to make money while you sleep.
                </p>
              </div>

              <div className="col-md-7 col-lg-6">
                <div className="speciAnimtn">
                  <div className="speciAnimtnBuble1"></div>
                  <div className="speciAnimtnBuble2"></div>
                  <div className="speciAnimtnBuble3"></div>
                </div>
                <ul class="speci">
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>Automatic passive income</h2>
                      <p>
                        Achieve automatic passive income daily from purchases,
                        take away, and delivery with our company.!
                      </p>
                    </div>
                  </li>

                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>No more disappointments</h2>
                      <p>
                        Invest with confidence knowing there will be no more
                        disappointments!
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>Limited number of participants</h2>
                      <p>
                        Secure your spot in the limited participation webinar by
                        registering now and start right away tomorrow!
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>No empty promises</h2>
                      <p>
                        Real-time earnings tracking available with no more
                        promises!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* ============================================================ */}

        <section className="bannersection">
          <div className="container">
            <div className="LognBtnFooterBox">
              <h3> Build Your Passive Income Portfolio Today</h3>
              <p>
                Maximize passive income with our top-notch businesses. From real
                estate to affiliate marketing, diversify and secure your
                financial future. Login or register for more details. Start
                building your portfolio today.!
              </p>

              <a class="loginBtnFooter" href="/auth/login">
                Login
              </a>
            </div>
          </div>
        </section>
        {/* ============================================================ */}

        <footer className="footersection">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto text-center">
                <img className="Logofooter" src={Apiwhite} />
              </div>
              <div className="col-md-5 mx-auto text-center">
                <Stack
                  sx={{
                    marginTop: "1rem",
                    marginBottom: "1.5rem",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "258px",
                  }}
                  spacing={1}
                >
                  <Box
                    sx={{
                      display: "grid",
                      rowGap: 1,
                      columnGap: 1,
                      gridTemplateColumns: {
                        md: "repeat(4,1fr)",
                      },
                    }}
                  >
                    <a
                      href="https://www.facebook.com/apiprogramm"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Facebook">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="logos:facebook"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>

                    <a
                      href="https://www.facebook.com/apiprogramm"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Instagram">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="skill-icons:instagram"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>

                    <a
                      href="https://www.tiktok.com/@apiprogramm?_t=8ZaT76WzvZG&_r=1"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Tiktok">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="logos:tiktok-icon"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>
                    <Typography>imPrint:</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Stack sx={{ marginTop: "-12px" }}>
                      {" "}
                      <p>
                        {/* imPrint :&nbsp; */}
                        <a
                          href="https://www.sparissimo.com/impressum"
                          target="_blank"
                        >
                          https://www.sparissimo.com/impressum
                        </a>
                      </p>{" "}
                    </Stack>
                  </Box>
                </Stack>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
};

export default Index;

import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const BinaryTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/bronzeTree/index"))
);
const SilverTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/silverTree/index"))
);
const GoldTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/goldTree/index"))
);
const BinaryLeg = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binaryLeg/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/treeNew/index"))
);

const Affiliate = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/affiliate/index"))
);
const Referrals = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/referrals/index"))
);

const genealogy = {
  path: "genealogy",
  children: [
    { index: true, element: <Navigate to="binary" /> },
    { path: "binaryLeg", element: <BinaryLeg /> },
    { path: "binary", element: <BinaryTree /> },
    { path: "silver", element: <SilverTree /> },
    { path: "gold", element: <GoldTree /> },
    { path: "sponsor", element: <Sponsor /> },
    { path: "affiliate", element: <Affiliate /> },
    { path: "referrals", element: <Referrals /> },
  ],
};

export default genealogy;

import { lazy } from "react";
import { Navigate } from "react-router";
import AuthGuard, { UserGuard } from "src/guards/AuthGuard";
import UserLayout from "src/layouts/user";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import financial from "./financial";
import genealogy from "./genealogy";
import helpCenter from "./helpCenter";
import profile from "./profile";
import subscriptions from "./subscriptions";
import tools from "./tools";

const ProductList = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/productList/index"
        )
    )
);
const ProductDetails = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/details/index"
        )
    )
);
const Checkout = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/checkout/index"
        )
    )
);
const MyOrders = Loadable(
    lazy(() => import("src/pages/userSide/onlineStore/myOrders/index"))
);

const InvoiceDetails = Loadable(
    lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);

const BlogPosts = Loadable(
    lazy(() => import("src/pages/userSide/blogs/BlogPosts"))
);
const BlogPost = Loadable(
    lazy(() => import("src/pages/userSide/blogs/BlogPost"))
);

const Dashboard = Loadable(
    lazy(() => import(`src/pages/userSide/dashboard/index`))
);

const IncomeReport = Loadable(
    lazy(() => import("src/pages/userSide/incomeReport"))
);
const MissedPoints = Loadable(
    lazy(() => import("src/pages/userSide/missedPoints"))
);

const Notices = Loadable(
    lazy(() => import("src/pages/userSide/notices/index"))
);

const user = {
    path: "user",
    element: (
        <AuthGuard>
            <UserGuard>
                <UserLayout />
            </UserGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        {
            path: "online-store",
            children: [
                {
                    path: "product-subscription",
                    children: [
                        { index: true, element: <ProductList /> },
                        {
                            path: "checkout",
                            element: <Checkout />,
                        },
                        { path: ":name", element: <ProductDetails /> },
                    ],
                },
                {
                    path: "my-orders",
                    children: [
                        { index: true, element: <MyOrders /> },
                        {
                            path: ":id",
                            element: <InvoiceDetails />,
                        },
                    ],
                },
            ],
        },
        {
            path: "blogs",
            children: [
                { index: true, element: <BlogPosts /> },
                { path: ":id", element: <BlogPost /> },
            ],
        },
        {
            path: "notices",
            element: <Notices />,
        },
        { path: "income-report", element: <IncomeReport /> },
        { path: "missed-points", element: <MissedPoints /> },
        { ...businessBuilder },
        { ...genealogy },
        { ...subscriptions },
        { ...financial },
        { ...helpCenter },
        { ...profile },
        { ...tools },
    ],
};

export default user;

// ----------------------------------------------------------------------

export const fullName = [
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
  "Shibi Bpract  ",
  "Sarath",
  "Rajesh Bpract",
];

export const firstName = ["shibi", "shibi"];

export const lastName = ["tr"];

import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import useLocales from "src/hooks/useLocales";

import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "src/components/hook-form";
import UsersSearch from "src/components/users/index.jsx";
import useFundForm from "./hooks/useFundForm";

const SendForm = ({ fetchData, refetch }) => {
  const { methods, onSubmit } = useFundForm(refetch, fetchData);
  const { translate } = useLocales();
  const {
    formState: { isSubmitting },
    watch,
  } = methods;
  console.log(methods.formState.errors);
  const showType = watch("type");

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  marginTop: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                }}
              >
                <RHFTextField
                  name="wallet"
                  disabled
                  value="ewallet"
                  label={translate("subFinancial.fundTrasfer.from")}
                  InputLabelProps={{ shrink: true }}
                ></RHFTextField>
                <RHFTextField
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  name="amount"
                  label={translate("subFinancial.eWallet.amount")}
                />
                {showType === "other" && (
                  <>
                    <UsersSearch name="user_id" />
                  </>
                )}

                <RHFSelect name="type" label={translate("type")}>
                  <option></option>
                  <option value="silver">{translate("silver")}</option>
                  <option value="gold">{translate("gold")}</option>
                  {/*<option value="other">{translate("other")}</option>*/}
                </RHFSelect>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  marginTop: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <RHFTextField
                  multiline
                  fullWidth
                  rows={3}
                  name="note"
                  label={translate("subFinancial.fundTrasfer.note")}
                />
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                >
                  {translate("send")}
                </LoadingButton>
              </Box>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendForm;

import { Box, Button, Fab, Stack, Toolbar, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IconButtonAnimate } from "src/components/animate";
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import Settings from "src/components/settings";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { RootStyle } from "src/layouts/shared";
import { PATH_DASHBOARD } from "src/routes/paths";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import LogoIcon from "src/layouts/shared/navbar/asssets/sparissimoapi.png";
import { PATH_USER } from "src/routes/paths";
import SIcon from "src/images/s-world.webp";
import redirectionIcon from "src/images/export.png";
import { useTheme } from "@emotion/react";
import useLocales from "src/hooks/useLocales";
import fetchUser from "src/utils/fetchUser";
DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const { translate } = useLocales();

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const { isAdmin } = useAuth();
  const theme = useTheme();
  const handlePlatform = async () => {
    try {
      const { status, data } = await fetchUser("sso-login");
      if (status) {
        window.open(data.data, "_blank");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && (
            <img src={LogoIcon} style={{ width: "80px" }} />
          )}

          {!isDesktop && (
            <IconButtonAnimate
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {!isAdmin ? (
            <Tooltip title="help center">
              <IconButtonAnimate
                LinkComponent={Link}
                to={PATH_USER.helpCenter.createTicket.subCategory()}
                sx={{ mr: 1, color: "text.disabled" }}
              >
                <Iconify icon="bx:support" />
              </IconButtonAnimate>
            </Tooltip>
          ) : null}

          {isAdmin ? (
            <>
              {/* <Tooltip title="products">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.store.products}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:shopping-cart-outline" />
                </IconButtonAnimate>
              </Tooltip> */}

              {/* <Tooltip title="materials">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.store.material}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:folder-outline" />
                </IconButtonAnimate>
              </Tooltip> */}
              <Tooltip title="help center">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.communication.help_center}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="bx:support" />
                </IconButtonAnimate>
              </Tooltip>
              <Tooltip title="network settings">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.settings.network}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:settings" />
                </IconButtonAnimate>
              </Tooltip>
            </>
          ) : null}

          {/* <Tooltip title="support">
            <IconButtonAnimate sx={{ mr: 1, color: "text.disabled" }}>
              <Iconify icon="bx:support" />
            </IconButtonAnimate>
          </Tooltip>
          <Tooltip title="ticket">
            <IconButtonAnimate sx={{ mr: 1, color: "text.disabled" }}>
              <Iconify icon="entypo:ticket" />
            </IconButtonAnimate>
          </Tooltip> */}

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {!isAdmin && (
              <IconButtonAnimate
                variant="outlined"
                // href="https://www.sparissimo.world/"

                // target="_blank"
                onClick={handlePlatform}
                sx={{
                  width: isDesktop ? "250px" : "100%",
                  borderRadius: "10px",
                  borderRadius: "25px",
                  backgroundColor: theme.palette.primary.main,
                  padding: isDesktop ? "7px 9px 7px 9px" : "4px 6px 4px 6px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={SIcon}
                  style={{
                    width: isDesktop ? 30 : 20,
                    height: isDesktop ? 30 : 20,
                    marginRight: isDesktop ? "5px" : "",
                  }}
                />
                <span
                  style={{
                    fontSize: isDesktop ? "16px" : "11px",
                    fontWeight: isDesktop ? 900 : 600,
                    marginLeft: 1,
                    color: "#fff",
                  }}
                >
                  {translate("our_platforms")}
                </span>
                {isDesktop && (
                  <img
                    src={redirectionIcon}
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "4px",
                      marginBottom: "5px",
                    }}
                  />
                )}
              </IconButtonAnimate>
            )}

            {/* <Searchbar /> */}
            <LanguagePopover />
            {/* <NotificationsPopover /> */}
            {/* <ContactsPopover /> */}
            <IconButtonAnimate onClick={() => handleOpenSettings()}>
              <Iconify icon="material-symbols:inbox-customize" />
            </IconButtonAnimate>
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>

      <Settings handleClose={handleCloseSettings} open={openSettings} />
    </>
  );
}

import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import { PATH_AUTH } from "src/routes/paths";

const NoAccountSection = () => {
  const smUp = useResponsive("up", "sm");

  return (
    !smUp && (
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        Don’t have an account &nbsp;?&nbsp;
        <a
          style={{
            color: "#DB5338",
            fontWeight: "bold",
            textDecoration: "none",
          }}
          href={process.env.REACT_APP_WORD_PRESS_REGISTER}
        >
          Get started
        </a>
      </Typography>
    )
  );
};

export default NoAccountSection;
